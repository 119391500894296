import { ActivatedRoute } from '@angular/router';
import { GuestPaymentDetailService } from './../../../_services/gpd.service';
import { Component } from '@angular/core';
import { CardDetails } from 'src/app/models/order';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrderService } from 'src/app/order.service';
import {Store} from '../../../models/store';

@Component({
  selector: 'app-wallet-card-detail',
  templateUrl: './wallet-card-detail.component.html',
  styleUrls: ['./wallet-card-detail.component.sass']
})
export class WalletCardDetailComponent {

  card: CardDetails
  token: string;
  form: FormGroup;
  storeId: string
  store: Store;

  constructor(private gpd: GuestPaymentDetailService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private orderService: OrderService) {
      this.token = this.route.snapshot.paramMap.get('token');
    }

  ngOnInit() {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    if(this.token){
      this.orderService.getStoreInfo(this.storeId).subscribe(store => {
        this.store = store;
        this.gpd.getGuestPaymentDetail(this.token, store.id).subscribe(data => {
          this.card = Object.assign(new CardDetails(), data);
          console.log(data);
          if(this.card){
            this.form = this.getForm(this.card);
          }
        });
      });
    }
  }

  getForm(card: CardDetails){
    return this.fb.group({
      id: [card.id],
      name_on_card: [card.name_on_card || ''],
      exp_month: [{value: card.getExpMonth() || '', disabled: true}],
      exp_year: [{value: card.getExpYear() || '', disabled: true}],
      cvc: [card.cvc || ''],
      zip_code: [''],
    })
  }

  goBack(){
    window.history.back()
  }

  deleteCard(){
    if(confirm('Are you sure you want to delete this card?')){
      this.orderService.deleteCard(this.card).subscribe(data => {
        this.goBack();
      })
    }
  }

  updateCard(){

  }
}
