import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/order.service';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { environment } from 'src/environments/environment';
import { Store } from 'src/app/models/store';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MultiStoreLocationComponent } from '../multi-store-location/multi-store-location.component';


@Component({
  selector: 'app-my-offers',
  templateUrl: './my-offers.component.html',
  styleUrls: ['./my-offers.component.sass']
})
export class MyOffersComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private orderService:OrderService,
    private router: Router,
    ngNavigatorShareService: NgNavigatorShareService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
   }

  private ngNavigatorShareService: NgNavigatorShareService;

  phoneNumber;
  otp;
  otpSent;
  phone_number_verified = false;
  myOffers;
  isLoading = true;
  hash;
  storeId;
  store;

  ngOnInit() {
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('hash');
    if (this.storeId) {
      this.orderService.getStoreInfo(this.storeId).subscribe(store => {
        this.store = Object.assign(new Store(), store);
        console.log('present 111111111');

      })
    }


    if(this.hash){
      this.orderService.getMyOffersWithHash(this.storeId, this.hash).subscribe(myOffers => {
        this.myOffers = myOffers
        this.phone_number_verified = true;
        this.isLoading = false;
      })
    }else{
      if(localStorage.getItem('phoneNumber') !== null){
        this.phone_number_verified = true;
        this.phoneNumber = localStorage.getItem('phoneNumber')
      }

      if(this.phone_number_verified){
        this.orderService.getMyOffers(this.phoneNumber).subscribe(myOffers => {
          this.myOffers = myOffers
          this.isLoading = false;
        })
      }
    }
  }


  sendOTP(){
    this.orderService.otpForUser(this.getPlainPhoneNumber()).subscribe(() => {
      this.otpSent = true;
    })
  }

  verifyOTP(){
    this.orderService.verifyOtpForUser(this.getPlainPhoneNumber(), this.otp).subscribe(data => {
      this.phone_number_verified = data['is_verified']
      localStorage.setItem("phoneNumber", data['phone_number'])
      localStorage.setItem("phoneNumberVerified", data['is_verified'])
      this.ngOnInit();
    })
  }

  changePhone(){
    this.phone_number_verified = false;
  }

  getPlainPhoneNumber(){
    if(this.phoneNumber){
      let ph = this.phoneNumber.replace(/[\s()-]/g, '')
      return '+1' + ph.substring(0,10)
    }
    return "";
  }

  getPlainPhoneNumberFromString(phoneNumber:string){
    let ph = phoneNumber.replace(/[\s()-]/g, '')
    return '+1' + ph.substring(0,10)
  }

  getFormattedPhoneNumber(phoneNumber){
    return phoneNumber.replace(/^(\+1)(\d{0,3})(\d{0,3})(\d{0,4})/, '($2) $3-$4')
  }

  redeem(offer){
    if (offer.offer && offer.offer.corporate) {
      this.orderService.accountStoreList(offer.offer.corporate).subscribe((data: any) => {
        const dialogRef = this.dialog.open(MultiStoreLocationComponent, {
          maxWidth: '390px',
          maxHeight: '600px',
          disableClose: true,
          data: {
            store_list: data.data
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
          this.orderService.getStoreInfo(result).subscribe(store => {
            this.store = Object.assign(new Store(), store);
            localStorage.setItem('currentStore', result);
            this.router.navigate([this.hash, 'my-offers', offer.id, 'redeem']);

          })
        });
      });
    } else {
      this.router.navigate([this.hash, 'my-offers', offer.id, 'redeem']);
    }
  }

  goBack(){
    window.history.back()
  }

  copyMessage(val: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open('Link copied to clipboard - share link with the new owner!', "OK", {
      duration: 5000,
      verticalPosition: 'top'})
  }

  confirm(offer){
    console.log(offer);

    console.log(this.router.url);
    let url = `${environment.hostName}/gift/${offer.offer_order.offer_order_id}/`
    if (!this.ngNavigatorShareService.canShare()) {
      this.copyMessage(url);
      return;
    }

    if(confirm('Once you gift this card and it’s claimed, you will no longer be able to use this card!')){
      this.ngNavigatorShareService.share({
        title: 'I got this for you!',
        text: 'I got this for you!\n\n',
        url: url
      }).then( (response) => {
        console.log(response);
      })
      .catch( (error) => {
        console.log(error);
        window.location.reload();
      });
    }
  }
}
