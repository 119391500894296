export class Constants{
  public static PHONE_NUMBER_KEY = "phoneNumber"
  public static pizzaOptions:Map<string, string> = new Map(Object.entries({
    "ADD": "WHOLE",
    "H1": "LEFT",
    "H2": "RIGHT",
    "Half": "HALF",
    "Lite": "LITE",
    "Extra": "EXTRA",
    "No": "NO",
    "Remove": "NO"
  }));

  public static occasion = {
    1: 'Pickup',
    2: 'Dine-In',
    3: 'Delivery',
  }


  public static occasionRi = {
    'PICKUP' : 1,
    'DINE-IN' : 2,
    'DELIVERY' : 3,
    'CURBSIDE' : 1
  }

}

