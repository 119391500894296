import { WalletCardDetailComponent } from './order-summary/wallet/wallet-card-detail/wallet-card-detail.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PaymentComponent } from './payment/payment.component';

import { RootComponent } from './root/root.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { MasterStoreComponent } from './store-lazy/master-store/master-store.component';
import { MenuItemMasterComponent } from './menu-item/menu-item-master/menu-item-master.component';
import { OrderSummaryMasterComponent } from './order-summary/order-summary-master/order-summary-master.component';
import { OffersComponent } from './offers/offers.component';
import { OfferDetailComponent } from './offers/offer-detail/offer-detail.component';
import { OfferSummaryComponent } from './offers/offer-summary/offer-summary.component';
import { OfferStatusComponent } from './offers/offer-status/offer-status.component';
import { MyOffersComponent } from './offers/my-offers/my-offers.component';
import { RedeemComponent } from './offers/redeem/redeem.component';
import { RedeemStatusComponent } from './offers/redeem-status/redeem-status.component';
import { RedeemHistoryComponent } from './offers/redeem-history/redeem-history.component';
import { GiftedComponent } from './offers/gifted/gifted.component';
import { HistoryComponent } from './history/history.component';
import { GiftedStatusComponent } from './offers/gifted-status/gifted-status.component';
import { OfferV2Component } from './offer-v2/offer-v2.component';
import { PendingChangesGuard } from './_helpers/pending-changes-gaurd';
import { UserappLoyaltyComponent } from './userapp-loyalty/userapp-loyalty.component';
import { PointsRedeemHistoryComponent } from './userapp-loyalty/points-redeem-history/points-redeem-history.component';
import { WalletComponent } from './order-summary/wallet/wallet.component';
import { SendGiftCardComponent } from './order-summary/wallet/send-gift-card/send-gift-card.component';
import { OrderSuspiciousComponent } from './order-suspicious/order-suspicious.component';
import { UserActivityComponent } from './user-activity/user-activity.component';
import { UserappReferralComponent } from './userapp-referral/userapp-referral.component';
import { UserappReferralRewardComponent } from './userapp-referral-reward/userapp-referral-reward.component';
import { CashierVarificationComponent } from './userapp-referral-reward/cashier-varification/cashier-varification.component';
import { CashierThankComponent } from './userapp-referral-reward/cashier-thank/cashier-thank.component';
import { ReferralMenuComponent } from './referral-menu/referral-menu.component';
import { ReferralPromotionComponent } from './userapp-referral/referral-promotion/referral-promotion.component';
import { ReferralPromotionValueComponent } from './userapp-referral/referral-promotion-value/referral-promotion-value.component';
import { ReferralPromotionOtpVarificationComponent } from './userapp-referral/referral-promotion-otp-varification/referral-promotion-otp-varification.component';
import { ReferralPromotionLinkComponent } from './userapp-referral/referral-promotion-link/referral-promotion-link.component';
import { ReferralVerificationComponent } from './userapp-referral-reward/referral-verification/referral-verification.component';
import { ReferralClaimComponent } from './userapp-referral-reward/referral-claim/referral-claim.component';

const routes: Routes = [
  { path: '', component: RootComponent, data: { pageTitle: 'Labrador AI' } },
  // order workflow
  // {path: 'order/:o_hash', component: OrderSummaryComponent, data: { pageTitle: 'Your Order'}},
  // {path: 'order/:o_hash/payment', component: PaymentComponent, data: { pageTitle: 'Payment'}},
  // {path: 'order/:o_hash/:menuItemGroupId/:menuItemId/:parsedMenuId/modifiers', component: ModifiersComponent, data: { pageTitle: 'Modifiers'}},
  // {path: 'order/:orderId/store', component: StoreComponent, data: { pageTitle: 'Store'}},
  // {path: 'order/:orderId/:menuItemId/new-modifiers', component: NewModifiersComponent, data: { pageTitle: 'Modifiers'}},
  {
    path: 'order/:o_hash/status/suspicious',
    component: OrderSuspiciousComponent,
  },
  {
    path: 'order/:o_hash/status/:status',
    component: OrderStatusComponent,
    data: { pageTitle: 'Order Submitted' }
  },
  {
    path: 'store/:storeId',
    component: MasterStoreComponent,
    data: { pageTitle: 'Master Store' }
  },
  {
    path: 'store/:storeId/menu-item-group/:menuItemGroupId',
    component: MenuItemMasterComponent,
    data: { pageTitle: 'Master MenuItem' }
  },
  {
    path: 'store/:storeId/offers/order/:offerOrderId',
    component: OfferSummaryComponent
  },
  {
    path: 'store/:storeId/offers',
    component: OffersComponent,
  },
  {
    path: 'store/:storeId/offers/order/:offerOrderId/available',
    component: OffersComponent,
  },
  {
    path: 'store/:storeId/offers/order/:offerOrderId/offer/:offerId',
    component: OfferDetailComponent,
    // runGuardsAndResolvers: 'always'
  },
  {
    path: 'store/:storeId/offers/:offerId',
    component: OfferDetailComponent,
    // runGuardsAndResolvers: 'always'
  },
  {
    path: 'store/:storeId/offer/order/:offerOrderId/status/:status',
    component: OfferStatusComponent,
  },
  {
    path: 'store/:storeId/:hash/my-offers',
    component: MyOffersComponent,
  },
  {
    path: 'store/:storeId/:hash/wallet',
    component: WalletComponent,
  },
  {
    path: 'store/:storeId/:hash/send-gift',
    component: SendGiftCardComponent,
  },
  {
    path: 'store/:storeId/:hash/wallet/:token',
    component: WalletCardDetailComponent,
  },
  {
    path: 'store/:storeId/:hash/my-offers/history',
    component: RedeemHistoryComponent,
    data: { pageTitle: 'Offer Submitted' }
  },
  {
    path: ':hash/my-offers/:offerId/redeem',
    component: RedeemComponent,
  },
  {
    path: 'gift/:giftId',
    component: GiftedComponent,
  },
  {
    path: 'gift/:giftId/:storeId/:userHash/success',
    component: GiftedStatusComponent,
  },
  {
    path: ':hash/my-offers/:offerId/redeem/:redeemId/status',
    component: RedeemStatusComponent,
    data: { pageTitle: 'Offer Submitted' }
  },
  {
    path: 'order/:orderId',
    component: OrderSummaryMasterComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'order/:orderId/wallet',
    component: WalletComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'order/:orderId/wallet/:token',
    component: WalletCardDetailComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'order/:orderId/store/:storeId',
    component: MasterStoreComponent,
  },
  {
    path: 'order/:orderId/store/:storeId/menu-item-group/:menuItemGroupId',
    component: MenuItemMasterComponent,
  },
  {
    path: 'order/:orderId/store/:storeId/menu-item-group/:menuItemGroupId/menu-item/:menuItemId/parsed-menu-item/:parsedMenuItem/modifiers',
    component: MenuItemMasterComponent,
  },
  {
    path: 'order/:orderId/add-payment',
    component: PaymentComponent
  },
  {
    path: 'store/:storeId/:userHash/history',
    component: HistoryComponent
  },
  {
    path: 'store/:storeId/order-offers',
    component: OfferV2Component
  },
  {
    path: 'order/:orderId/store/:storeId/order-offers',
    component: OfferV2Component
  },
  {
    path: 'store/:storeId/:userHash/loyalty',
    component: UserappLoyaltyComponent
  },
  {
    path: 'store/:storeId/:userHash/activity',
    component: UserActivityComponent
  },
  {
    path: 'store/:storeId/:hash/points-redeem-history',
    component: PointsRedeemHistoryComponent,
    data: { pageTitle: 'Offer Submitted' }
  },

  {
    path: 'store/:storeId/referral-menu',
    component: ReferralMenuComponent
  },
  {
    path: 'store/:storeId/referral',
    component: UserappReferralComponent
  },
  {
    path: 'store/:storeId/referral-promotion',
    component: ReferralPromotionComponent
  },
  {
    path: 'store/:storeId/referral-promotion-value',
    component: ReferralPromotionValueComponent
  },
  {
    path: 'store/:storeId/referral-promotion-otp-varification',
    component: ReferralPromotionOtpVarificationComponent
  },

  {
    path: 'store/:storeId/referral-promotion-code',
    component: ReferralPromotionLinkComponent
  },

  {
    path: ':referralKey/:id',
    component: UserappReferralRewardComponent
  },
  {
    path: ':referralKey/:id/:storeId/verification',
    component: ReferralVerificationComponent
  },

  {
    path: ':referralKey/:id/:storeId/verification/claim',
    component: ReferralClaimComponent
  },


  // {
  //   path: 'store/:storeId/:userHash/referral/:referralKey/:id/:storeId/varification',
  //   component: CashierVarificationComponent
  // },

  {
    path: 'store/:storeId/referral/varification',
    component: CashierVarificationComponent
  },

  {
    path: 'store/:storeId/referral/',
    component: CashierVarificationComponent
  },
  // {
  //   path: 'store/:storeId/:userHash/referral/:referralKey/:id/:storeId/varification/thank',
  //   component: CashierThankComponent
  // }, 
  {
    path: 'store/:storeId/referral/varification/thank',
    component: CashierThankComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
