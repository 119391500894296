import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/order.service';
import { Store } from 'src/app/models/store';

@Component({
  selector: 'app-redeem-status',
  templateUrl: './redeem-status.component.html',
  styleUrls: ['./redeem-status.component.sass']
})
export class RedeemStatusComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private orderService:OrderService,
    private router: Router) { }

  offerId;
    offerItem;
    store;
    redeemAmount;
    redeemId;
    redeem;
    isLoading = true;
    hash

  ngOnInit() {
    this.hash = this.route.snapshot.paramMap.get('hash');
    this.offerId = this.route.snapshot.paramMap.get('offerId');
    this.redeemId = this.route.snapshot.paramMap.get('redeemId');

    this.orderService.getOfferItemWithHash(this.hash, this.offerId).subscribe(offer => {
      this.offerItem = offer
      this.orderService.getStoreInfo(this.offerItem.offer.store).subscribe(store => this.store=Object.assign(new Store(), store))
      this.orderService.redeemHistory(this.hash, this.offerId, this.redeemId).subscribe(history => this.redeem = history)
      this.redeemAmount = offer.total_list_price - offer.redeemed_amount;
      this.isLoading = false;
    })
  }

  goBack(){
    window.history.back()
  }
}
