import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { Store } from 'src/app/models/store';
import { OrderService } from 'src/app/order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-referral-promotion-otp-varification',
  templateUrl: './referral-promotion-otp-varification.component.html',
  styleUrls: ['./referral-promotion-otp-varification.component.sass']
})
export class ReferralPromotionOtpVarificationComponent {
  store;
  offerCatalogSharableLink;
  storeId: any;
  isLoading = true;
  store_id: any;
  hash: any;
  phoneNumber: any;
  FilterPromot: any;
  otpValues: string[] = ['', '', '', '', '', ''];
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;
  @ViewChild('input5') input5: ElementRef;
  @ViewChild('input6') input6: ElementRef;
  enteredOTP: string;
  errorMessage: any = '';
  referralCode: any;
  referralCodeSharableLink: string;
  locationhost: string;
  errorMessage1: string;
  idSet: any;
  phoneNumVal: any;
  hideHeader: boolean = false;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    ngNavigatorShareService: NgNavigatorShareService,
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
  }
  private ngNavigatorShareService: NgNavigatorShareService;

  ngOnInit(): void {
    if (environment.referralHost.includes(window.location.origin)) {
      this.hideHeader = true;
    }
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.store_id = localStorage.getItem('currentStore');
    this.phoneNumVal = localStorage.getItem('phone_num_val');

    this.FilterPromot = JSON.parse(localStorage.getItem("referee_gets"));
    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      if (this.store.dynamic_offer_url) {
        this.offerCatalogSharableLink = this.store.dynamic_offer_url;
      } else {
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`
      }
      this.isLoading = false;
    });
    this.referralLinkData();
  }
  goBack() {
    window.history.back()
  }

  referralLinkData() {
    let keySend = {
      "store_id": this.store_id,
      "phone_number": this.phoneNumVal,
      "referral_promotion_id": this.FilterPromot.id
    }
    this.orderService.referralLinkData(keySend).subscribe(data => {
      this.idSet = data.data[0].id;
    })
  }

  focusNext(index: number) {
    if (index < this.otpValues.length) {
      const nextInput = this[`input${index + 1}`] as ElementRef;
      nextInput.nativeElement.focus();
    }
  }
  onLastInput() {
    this.enteredOTP = this.otpValues.join('');
  }


  verifyOTP() {
    this.store_id = localStorage.getItem('currentStore')
    let keySend = {
      "phone_number": this.phoneNumVal,
      "otp": this.enteredOTP,
      "store_id": this.store_id,
      "promotionId": this.FilterPromot.id,
      "referral_promotion": this.FilterPromot.id,
      "user_hash": this.hash,
      "url_slug": this.store.url_slug,
      "url_host": location.host,
    }
    this.orderService.verifyOTP(this.idSet, keySend).subscribe(data => {
      if (data.is_verified == true) {
        this.referralCode = data.referral_code;
        this.referralCodeSharableLink = `${location.host}/store/${this.store.url_slug}/${this.hash}/referral/${this.referralCode}/${this.FilterPromot.id}/${this.store_id}`;
        this.locationhost = location.host;
        localStorage.setItem('phoneNumber', this.phoneNumVal)
        this.router.navigate(['store/' + this.storeId + '/referral-promotion-code']);
      } else {
        this.otpValues = [];
        this.errorMessage1 = 'OTP verification failed. Please try again.';
      }
    });
  }
}
