import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { OrdersWithPayment } from 'src/app/models/order';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return (control && control.invalid);
  }
}

@Component({
  selector: 'app-order-summary-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss']
})
export class InstructionComponent implements OnInit {
  @Input() orderWithPayment:OrdersWithPayment;

  @Output() instChange = new EventEmitter<string>()
  @Output() nameChange = new EventEmitter<string>()

  @ViewChild('nameInput', {static: true}) nameInput;

  matcher = new MyErrorStateMatcher();
  labelInput:any;

  constructor() { }

  ngOnInit() {
    this.labelInput = this.orderWithPayment.store && this.orderWithPayment.store.ui_settings;
  }

  instructionChange(instruction:string){
    this.instChange.emit(instruction)
  }

  orderNameChange(name:string){
    localStorage.setItem('orderName', name);
    this.nameChange.emit(name)
  }
}
