import { Injectable, OnInit } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { map, catchError, tap } from "rxjs/operators";
import {
  MenuItem,
  Order,
  EditModifierOptions,
  OrdersWithPayment,
  ModifierGroups,
  MenuItemGroup,
  ModifierOption,
  CardDetails,
  PaymentMethods,
  HostedMenu,
  Modifier,
} from "./models/order";
import { environment } from "../environments/environment";
import { StoreMenuItems, StoreMenuItemsV2, Store } from "./models/store";
import { WalletData } from "./models/wallet";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  endpoint = environment.orderApiBackend;
  token = environment.accessToken;
  graphmenu = this.endpoint;
  orderEndpoint = `${this.endpoint}/backend/order/`;
  storeEndpoint = `${this.endpoint}/backend/store/`;
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Token ${this.token}`,
    }),
  };

  generateHeaders(): HttpHeaders {
    var httpHeaders = null;
    var token = JSON.parse(localStorage.getItem("currentUser"));
    if (token) {
      httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${token.token}`,
      });
    } else {
      httpHeaders = new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
      });
    }
    return httpHeaders;
  }

  constructor(private http: HttpClient) { }

  getOrder(orderId: string): Observable<OrdersWithPayment> {
    return this.http.get<OrdersWithPayment>(
      this.orderEndpoint + orderId + "/",
      this.httpOptions
    );
  }

  getOrderHistory(
    storeId: string,
    userHash: string,
    search: string
  ): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.storeEndpoint}${storeId}/${userHash}/history/?q=${search}`,
      this.httpOptions
    );
  }

  getModifiers(orderId: string, menuItemId: string) {
    return this.http.get<EditModifierOptions>(
      this.orderEndpoint + orderId + "/" + menuItemId + "/modifiers/",
      this.httpOptions
    );
  }

  getNewModifiers(orderId: string, menuItemId: string) {
    return this.http.get<ModifierGroups>(
      this.orderEndpoint + orderId + "/" + menuItemId + "/new-modifiers/",
      this.httpOptions
    );
  }

  updateItem(
    orderId: string,
    parsedMenuItemId: string,
    menuItemId: string,
    modifiers: Modifier[],
    quantity: number,
    special_request: string
  ) {
    const options = { headers: { "Content-Type": "application/json" } };
    let payload = {
      menu_item_id: menuItemId,
      updated_modifiers: modifiers,
      quantity: quantity,
      special_request: special_request,
    };
    return this.http.post(
      this.orderEndpoint + orderId + "/" + parsedMenuItemId + "/modifiers/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  addnewItem(
    orderId: string,
    menuItemId: string,
    modifiers: Modifier[],
    quantity: number,
    special_request: string
  ) {
    let payload = {
      modifiers: modifiers,
      quantity: quantity,
      special_request: special_request,
    };
    return this.http.post(
      this.orderEndpoint + orderId + "/" + menuItemId + "/new-modifiers/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  removeItem(orderId: string, menuItemId: string) {
    return this.http.get<EditModifierOptions>(
      this.orderEndpoint + orderId + "/" + menuItemId + "/remove/",
      this.httpOptions
    );
  }

  addItem(
    orderId: string,
    menuItemId: number,
    specialRequest: string,
    quantity: number
  ) {
    return this.http.post(
      this.orderEndpoint + orderId + "/add/",
      JSON.stringify({
        menu_item_id: menuItemId,
        special_request: specialRequest,
        quantity: quantity,
      }),
      this.httpOptions
    );
  }

  getStoreMenu(orderId: string) {
    return this.http.get<StoreMenuItemsV2>(
      this.orderEndpoint + orderId + "/store/",
      this.httpOptions
    );
  }

  getStoreMenuV2(storeId: string, filter: string) {
    let params = { params: new HttpParams().set("filter", filter) };
    let updatedOptions = { ...params, ...this.httpOptions };
    return this.http.get<StoreMenuItemsV2>(
      this.endpoint + "/backend/store/" + storeId + "/menu_item_groups/",
      updatedOptions
    );
  }

  getStoreMenuV2Lazy(storeId: string, filter: string) {
    let params = { params: new HttpParams().set("filter", filter) };
    let updatedOptions = { ...params, ...this.httpOptions };
    return this.http.get<StoreMenuItemsV2>(
      this.endpoint + "/backend/store/" + storeId + "/lazy_menu_item_groups/",
      updatedOptions
    );
  }

  getStoreMenuV2LazyIncremental(
    storeId: string,
    filter: string,
    limit: string,
    offset: string,
    menu_group_id: string,
    group: string,
    subgroup: string
  ) {
    let params = {
      params: new HttpParams()
        .set("filter", filter)
        .set("limit", limit)
        .set("offset", offset)
        .set("menu_group_id", menu_group_id)
        .set("group", group)
        .set("subgroup", subgroup),
    };
    let updatedOptions = { ...params, ...this.httpOptions };
    return this.http.get<any[]>(
      this.endpoint +
      "/backend/store/" +
      storeId +
      "/lazy_menu_item_groups/incremental/",
      updatedOptions
    );
  }

  getMenuItemGroupOptions(orderId: string, menuItemGroupId: string) {
    return this.http.get<MenuItem[]>(
      this.orderEndpoint +
      orderId +
      "/menu_item_groups/" +
      menuItemGroupId +
      "/",
      this.httpOptions
    );
  }
  getMenuItemGroupOptionsForStore(storeId: string, menuItemGroupId: string) {
    return this.http.get<MenuItem[]>(
      this.storeEndpoint +
      storeId +
      "/menu_item_groups/" +
      menuItemGroupId +
      "/menu_items/",
      this.httpOptions
    );
  }

  getModifiersForMenuItem(storeId: string, menuItemId: string) {
    return this.http.get<ModifierGroups>(
      this.storeEndpoint + storeId + "/menu_item/" + menuItemId + "/modifiers/",
      this.httpOptions
    );
  }

  submitOrder(orderId: string, optIn: boolean) {
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.http.get<string>(
      this.orderEndpoint +
      orderId +
      `/submit/?opt_in=${optIn}&timezone=${timezone}`,
      this.httpOptions
    );
  }

  submitTempOrder(orderId: string, payload: any) {
    return this.http.post<string>(
      this.orderEndpoint + orderId + "/submit_temp/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  updateQuantity(
    orderId: string,
    parsed_menu_item_id: number,
    quantity: number
  ) {
    const options = { headers: { "Content-Type": "application/json" } };
    return this.http.post<string>(
      this.orderEndpoint +
      orderId +
      "/" +
      parsed_menu_item_id +
      "/update_quantity/",
      JSON.stringify({ quantity: quantity }),
      this.httpOptions
    );
  }

  deleteItem(orderId: string, parsed_menu_item_id: number) {
    return this.http.get<string>(
      this.orderEndpoint + orderId + "/" + parsed_menu_item_id + "/delete/",
      this.httpOptions
    );
  }

  updateOccasionSchedule(orderId: string, payload: any) {
    return this.http.post<string>(
      this.orderEndpoint + orderId + "/update_occasion_schedule/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  updateTip(orderId: string, tip: number) {
    return this.http.post<string>(
      this.orderEndpoint + orderId + "/update_tip/",
      JSON.stringify({ tip_percent: tip }),
      this.httpOptions
    );
  }

  updateCustomTip(orderId: string, tip: number) {
    return this.http.post<string>(
      this.orderEndpoint + orderId + "/update_tip/",
      JSON.stringify({ tip: tip }),
      this.httpOptions
    );
  }

  curbsideConfirm(orderId: string, data: any) {
    return this.http.get<string>(
      this.orderEndpoint + orderId + "/curbside_confirm/" + '?p_spot=' + data,
      this.httpOptions
    );
  }

  reOrder(orderId: string) {
    return this.http.get<any>(
      this.orderEndpoint + orderId + "/reorder/",
      this.httpOptions
    );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  addNewBotOrder(storeId: string, payload: Map<string, any>) {
    const options = { headers: { "Content-Type": "application/json" } };
    return this.http.post<string>(
      "/backend/order/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  getStoreInfo(storeId: string) {
    return this.http.get<Store>(
      this.storeEndpoint + storeId + "/",
      this.httpOptions
    );
  }

  getMenuGroup(storeId: string, menuItemGroupId: number) {
    console.log(storeId);
    return this.http.get<MenuItemGroup>(
      `${this.storeEndpoint}${storeId}/menu_item_group/${menuItemGroupId}/`,
      this.httpOptions
    );
  }

  createTempOrder(storeId: string, userHash: string = "") {
    return this.http.get(
      `${this.orderEndpoint}temp/${storeId}/?user_hash=${userHash}`,
      this.httpOptions
    );
  }

  createTempOrderWithPhone(
    storeId: string,
    phoneNumber: string,
    promoCode: string = null
  ) {
    const payload = { phone_number: phoneNumber, promo_code: promoCode };
    return this.http.post(
      this.orderEndpoint + "temp/" + storeId + "/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  updateOrderInstructions(
    orderHash: string,
    instruction: string,
    name: string
  ) {
    const options = { headers: { "Content-Type": "application/json" } };
    const payload = { special_request: instruction, order_name: name };
    return this.http.post<string>(
      this.orderEndpoint + orderHash + "/update_instruction/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  closeCashOrder(orderHash, payload) {
    return this.http.post<any>(
      this.orderEndpoint + orderHash + "/close_cash_order/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  payLater(orderHash, payload) {
    return this.http.post<any>(
      this.orderEndpoint + orderHash + "/pay_later/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  paddy(num, padlen, padchar) {
    var pad_char = typeof padchar !== "undefined" ? padchar : "0";
    var pad = new Array(1 + padlen).join(pad_char);
    return (pad + num).slice(-pad.length);
  }

  addNewCard(
    storeId: string,
    phone_number: string,
    name_on_card: string,
    expiryMonth: string,
    expiryYear: string,
    cvc: string,
    token: string,
    addToWallet: boolean
  ) {
    return this.http.post(
      this.endpoint + "/backend/payment/new_card/",
      JSON.stringify({
        phone_number: phone_number,
        store_id: storeId,
        name_on_card: name_on_card,
        token: token,
        expiry: `${this.paddy(expiryMonth, 2, "0")}${this.paddy(
          expiryYear,
          2,
          "0"
        )}`,
        cvc: cvc,
        add_to_wallet: addToWallet,
      }),
      this.httpOptions
    );
  }

  addNewCardGateway(payload) {
    return this.http.post(
      this.endpoint + "/backend/payment/gateway/new_card/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  addNewStripeCard(
    storeId: string,
    phone_number: string,
    name_on_card: string,
    expiryMonth: string,
    expiryYear: string,
    cvc: string,
    token: string,
    last_4: string,
    card_type: string,
    zip: string = ""
  ) {
    return this.http.post(
      this.endpoint + "/backend/payment/new_stripe_card/",
      JSON.stringify({
        phone_number: phone_number,
        store_id: storeId,
        name_on_card: name_on_card,
        token: token,
        expiry: `${expiryMonth}${expiryYear}`,
        cvc: cvc,
        last_4: last_4,
        card_type: card_type,
        zip: zip,
      }),
      this.httpOptions
    );
  }

  getCard(orderHash: string) {
    return this.http.get(
      this.endpoint + "/backend/payment/?order_hash=" + orderHash,
      this.httpOptions
    );
  }

  getCards(storeId: string, phoneNumber: string, paymentGateway: string) {
    return this.http.get<WalletData>(
      `${this.endpoint
      }/backend/payment/?store_id=${storeId}&phone_number=${encodeURIComponent(
        phoneNumber
      )}&payment_gateway=${paymentGateway}`,
      this.httpOptions
    );
  }

  updatePhoneNumber(orderHash: string, phoneNumber: string) {
    return this.http.post<string>(
      this.orderEndpoint + orderHash + "/update_phone_number/",
      JSON.stringify({ phone_number: phoneNumber }),
      this.httpOptions
    );
  }

  getPreferredPayment(orderId: string, paymentGateway: string) {
    return this.http.post<CardDetails>(
      this.orderEndpoint + orderId + "/get_preferred_payment/",
      JSON.stringify({ payment_gateway: paymentGateway }),
      this.httpOptions
    );
  }

  updatePreferredPayment(orderId: string, payload: any) {
    return this.http.post<string>(
      this.orderEndpoint + orderId + "/updated_preferred_payment/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  updatePreferredPaymentAsCash(
    orderId: string,
    useStoreCredit: boolean,
    creditAmount: number
  ) {
    let payload = {
      is_cash: true,
    };

    if (useStoreCredit) {
      payload["is_store_credit"] = useStoreCredit;
      payload["store_credit_amount"] = creditAmount;
    }

    return this.http.post<string>(
      this.orderEndpoint + orderId + "/updated_preferred_payment/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  updatePreferredPaymentAsCard(
    orderId: string,
    guestPaymentDetails: number,
    paymentGateway: string,
    includeStoreCredit: boolean,
    creditAmount: number
  ) {
    let payload = {
      guest_payment_id: guestPaymentDetails,
      payment_gateway: paymentGateway,
    };

    if (includeStoreCredit) {
      payload["is_store_credit"] = includeStoreCredit;
      payload["store_credit_amount"] = creditAmount;
    }

    return this.http.post<string>(
      this.orderEndpoint + orderId + "/updated_preferred_payment/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  updatePreferredPaymentAsStoreCredit(orderId: string, creditAmount: number) {
    let payload = {
      is_store_credit: true,
      store_credit_amount: creditAmount,
    };

    return this.http.post<string>(
      this.orderEndpoint + orderId + "/updated_preferred_payment/",
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  getLastPreferredPayment(
    orderId: string,
    phoneNumber: string,
    paymentGateway: string
  ) {
    return this.http.post<string>(
      this.orderEndpoint + orderId + "/get_last_preferred_payment/",
      JSON.stringify({
        phone_number: phoneNumber,
        payment_gateway: paymentGateway,
      }),
      this.httpOptions
    );
  }

  getHostedMenu(storeId: string) {
    return this.http.get<HostedMenu[]>(
      this.storeEndpoint + storeId + "/hosted_menu/",
      this.httpOptions
    );
  }

  otp(orderId: string, phoneNumber: string) {
    return this.http.post<string>(
      this.orderEndpoint + orderId + "/otp/",
      JSON.stringify({ phone_number: phoneNumber }),
      this.httpOptions
    );
  }

  verifyOtp(orderId: string, phoneNumber: string, otp: number) {
    return this.http.post<string>(
      this.orderEndpoint + orderId + "/otp/verify/",
      JSON.stringify({ phone_number: phoneNumber, otp: otp }),
      this.httpOptions
    );
  }

  deleteCard(cardDetails: CardDetails) {
    return this.http.post<any>(
      this.endpoint + "/backend/payment/delete/",
      JSON.stringify(cardDetails),
      this.httpOptions
    );
  }

  offers(storeId: string, offerOrderId: string) {
    return this.http.get<any>(
      this.endpoint +
      `/backend/store/${storeId}/offers/order/${offerOrderId}/available/`,
      this.httpOptions
    );
  }

  getOffer(storeId: string, offerOrderId: string) {
    return this.http.get<any>(
      this.endpoint + `/backend/store/${storeId}/offers/order/${offerOrderId}/`,
      this.httpOptions
    );
  }

  getOfferDetails(storeId: string, offerOrderId: string, offerId: string) {
    return this.http.get<any[]>(
      this.endpoint +
      `/backend/store/${storeId}/offers/order/${offerOrderId}/offer/${offerId}/`,
      this.httpOptions
    );
  }

  addOffer(storeId: string, offerOrderId: string, offerId: string, customPrice: any, cardType: any) {
    return this.http.get<any>(
      this.endpoint +
      `/backend/store/${storeId}/offers/order/${offerOrderId}/offer/${offerId}/${customPrice}/${cardType}/add/`,
      this.httpOptions
    );
  }

  updateOfferQuantity(storeId, offerOrderId, offerId, quantity) {
    return this.http.post<any>(
      this.endpoint +
      `/backend/store/${storeId}/offers/order/${offerOrderId}/offer/${offerId}/update_quantity/`,
      JSON.stringify({ quantity: quantity }),
      this.httpOptions
    );
  }

  deleteOffer(storeId, offerOrderId, offerId) {
    return this.http.get<any>(
      this.endpoint +
      `/backend/store/${storeId}/offers/order/${offerOrderId}/offer/${offerId}/delete/`,
      this.httpOptions
    );
  }

  getLastPreferredPaymentForOffer(storeId, offerOrderId, phoneNumber) {
    return this.http.post<any>(
      this.endpoint +
      `/backend/store/${storeId}/offers/order/${offerOrderId}/get_last_preferred_payment/`,
      JSON.stringify({ phone_number: phoneNumber }),
      this.httpOptions
    );
  }

  updatePreferredPaymentAsCardForOffer(
    storeId,
    offerOrderId,
    guestPaymentDetails: number
  ) {
    return this.http.post<string>(
      this.endpoint +
      `/backend/store/${storeId}/offers/order/${offerOrderId}/updated_preferred_payment/`,
      JSON.stringify({ guest_payment_id: guestPaymentDetails }),
      this.httpOptions
    );
  }

  getPreferredPaymentForOffer(
    storeId,
    offerOrderId,
    phoneNumber,
    paymentGateway
  ) {
    return this.http.post<string>(
      this.endpoint +
      `/backend/offers/order/${offerOrderId}/get_preferred_payment/`,
      JSON.stringify({ payment_gateway: paymentGateway }),
      this.httpOptions
    );
  }

  submitOfferOrder(storeId, offerOrderId) {
    return this.http.get<string>(
      this.endpoint +
      `/backend/store/${storeId}/offers/order/${offerOrderId}/submit/`,
      this.httpOptions
    );
  }

  createTempOfferOrder(storeId) {
    return this.http.get<string>(
      this.endpoint + `/backend/store/${storeId}/offers/temp/`,
      this.httpOptions
    );
  }

  createTempOfferOrderWithPhone(storeId, phoneNumber) {
    return this.http.post<string>(
      this.endpoint + `/backend/store/${storeId}/offers/temp/`,
      JSON.stringify({ phone_number: phoneNumber }),
      this.httpOptions
    );
  }

  otpForOffer(
    storeId: string,
    offerOrderId: string,
    phoneNumber: string,
    forGift = false
  ) {
    return this.http.post<string>(
      this.endpoint +
      `/backend/store/${storeId}/offers/order/${offerOrderId}/otp/`,
      JSON.stringify({ phone_number: phoneNumber, for_gift: forGift }),
      this.httpOptions
    );
  }

  verifyOtpForOffer(
    storeId: string,
    offerOrderId: string,
    phoneNumber: string,
    otp: number,
    forGift = false
  ) {
    return this.http.post<string>(
      this.endpoint +
      `/backend/store/${storeId}/offers/order/${offerOrderId}/otp/verify/`,
      JSON.stringify({
        phone_number: phoneNumber,
        otp: otp,
        for_gift: forGift,
      }),
      this.httpOptions
    );
  }

  otpForUser(phoneNumber: string) {
    return this.http.post<string>(
      this.endpoint + `/backend/user/otp/`,
      JSON.stringify({ phone_number: phoneNumber }),
      this.httpOptions
    );
  }

  verifyOtpForUser(phoneNumber: string, otp: number) {
    return this.http.post<string>(
      this.endpoint + `/backend/user/otp/verify/`,
      JSON.stringify({ phone_number: phoneNumber, otp: otp }),
      this.httpOptions
    );
  }

  getMyOffers(phoneNumber: string) {
    return this.http.post<any[]>(
      this.endpoint + `/backend/offers/my_offers/`,
      JSON.stringify({ phone_number: phoneNumber }),
      this.httpOptions
    );
  }

  getMyOffersWithHash(storeId: string, hash: string) {
    return this.http.get<any[]>(
      this.endpoint + `/backend/offers/${storeId}/${hash}/my_offers/`,
      this.httpOptions
    );
  }

  getOfferItem(id) {
    return this.http.get<any>(
      this.endpoint + `/backend/offers/my_offers/${id}/`,
      this.httpOptions
    );
  }

  getOfferItemWithHash(hash, id) {
    return this.http.get<any>(
      this.endpoint + `/backend/offers/my_offers/${hash}/${id}/`,
      this.httpOptions
    );
  }

  redeemOfferItem(hash, id, redeem_amount, storId: any) {
    return this.http.post<any>(
      this.endpoint + `/backend/offers/my_offers/${hash}/${id}/redeem/`,
      JSON.stringify({ redeem_amount: redeem_amount, store_id: storId }),
      this.httpOptions
    );
  }

  redeemHistory(hash, offerId, redeemId) {
    return this.http.get<any>(
      this.endpoint +
      `/backend/offers/my_offers/${hash}/${offerId}/redeem/${redeemId}`,
      this.httpOptions
    );
  }

  redeemHistoryAll(hash) {
    return this.http.get<any>(
      this.endpoint + `/backend/offers/my_offers/${hash}/history/`,
      this.httpOptions
    );
  }

  submitTablesideOpenTab(orderId) {
    return this.http.get<string>(
      this.orderEndpoint + orderId + "/submit_tableside_open_tab/",
      this.httpOptions
    );
  }

  closeTableside(orderId) {
    return this.http.get<string>(
      this.orderEndpoint + orderId + `/close_tableside/`,
      this.httpOptions
    );
  }

  submitTablesideConfirm(orderId, optIn) {
    return this.http.get<string>(
      this.orderEndpoint +
      orderId +
      `/submit_tableside_checkout/?opt_in=${optIn}`,
      this.httpOptions
    );
  }

  getTransxHPPHash(storeId, orderHash) {
    let params = new HttpParams()
      .set("store_id", storeId)
      .set("order_hash", orderHash);
    return this.http.get<any>(`${this.endpoint}/backend/gateway/transx/hpp/`, {
      params,
    });
  }

  getOrderFeedback(orderHash) {
    return this.http.get<any>(
      `${this.endpoint}/backend/v2/orders/feedback/${orderHash}/`,
      this.httpOptions
    );
  }

  sumitOrderFeedback(payload) {
    return this.http.post<any>(
      `${this.endpoint}/backend/v2/orders/feedback/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  getTablesideTables(storeId) {
    return this.http.get<any>(
      `${this.endpoint}/backend/v2/tableside/table/${storeId}/`,
      this.httpOptions
    );
  }

  getStoreLogo(storeId) {
    return this.http.get<any>(
      `${this.endpoint}/backend/v2/stores/logo/${storeId}/`,
      this.httpOptions
    );
  }

  getGiftDetails(giftId) {
    return this.http.get<any>(
      `${this.endpoint}/backend/v2/gift/${giftId}/get_gift/`,
      this.httpOptions
    );
  }

  claimGift(giftId, phoneNumber) {
    return this.http.post<any>(
      `${this.endpoint}/backend/v2/gift/${giftId}/claim_gift/`,
      JSON.stringify({ phone_number: phoneNumber }),
      this.httpOptions
    );
  }

  getOffersV2(storeId: string) {
    return this.http.get<any>(
      this.endpoint + `/backend/v2/offers-v2/${storeId}/`,
      this.httpOptions
    );
  }

  removePromoCode(orderId) {
    return this.http.get<any>(
      this.orderEndpoint + orderId + "/remove_promo_code/",
      this.httpOptions
    );
  }

  applyPromoCode(orderId, promoCode) {
    return this.http.post<any>(
      this.orderEndpoint + orderId + "/apply_promo_code/",
      JSON.stringify({ promo_code: promoCode }),
      this.httpOptions
    );
  }

  getServiceAreas(store_id) {
    return this.http.get<any>(
      `${this.endpoint}/backend/v2/service-area/${store_id}/`,
      this.httpOptions
    );
  }

  updateDeliveryFee(orderId, delivery_fee) {
    let payload = { delivery_fee: delivery_fee };
    return this.http.post<any>(
      `${this.endpoint}/backend/v2/orders/${orderId}/update_delivery_fee/`,
      JSON.stringify(payload),
      this.httpOptions
    );
  }

  updateDisclaimerShow(store: Store, data: any) {
    return this.http.post<any>(this.graphmenu + `/backend/store/${store}/disclaimer_detail_status_update/`, data, this.httpOptions)
  }

  useGiftOrder(hash: any) {
    const payload = { gift_use: true };
    return this.http.post<any>(this.graphmenu + `/backend/order/${hash}/use_gift/`, payload, this.httpOptions);
  }

  checkNumberBlocked(store_id: any, phone_number: any) {
    return this.http.get<any>(`${this.endpoint}/backend/store/${store_id}/${phone_number}/blocked_unblocked/`, this.httpOptions);
  }

  pauseStoreService(storeId: any, payload: any) {
    return this.http.put(`${this.endpoint}/backend/store/${storeId}/pause_store/`, payload, this.httpOptions);
  }

  checkReorderMenuItem(order_hash: any) {
    return this.http.get<any>(`${this.endpoint}/backend/order/${order_hash}/reorder_item_check/`, this.httpOptions);
  }

  accountStoreList(corporate_id: any) {
    return this.http.get<any>(this.graphmenu + `/backend/orders/${corporate_id}/store_list/`, this.httpOptions);
  }


  promotionList(id) {
    return this.http.get<any>(`${this.endpoint}/backend/referral_setting_filter_get/${id}/`, this.httpOptions);
  }
  refereeRegistration(data: any) {
    return this.http.post<any>(`${this.endpoint}/backend/referee_registration/`, data, this.httpOptions);
  }
  refereeRegistrationOTP(id, data: any) {
    return this.http.post<any>(`${this.endpoint}/backend/store/referee_registration/otp/`, data, this.httpOptions);
  }
  verifyOTP(id, data: any) {
    return this.http.post<any>(`${this.endpoint}/backend/store/referee_registration/${id}/otp/verify/`, data, this.httpOptions);
  }
  claimForm(data: any) {
    return this.http.post<any>(`${this.endpoint}/backend/store/referral_code/claim/`, data, this.httpOptions);
  }
  claimGet(data: any) {
    return this.http.post<any>(`${this.endpoint}/backend/store/referral_code/verify_referral_code/`, data, this.httpOptions);
  }

  storeIdReferral(data: any) {
    return this.http.post<any>(`${this.endpoint}/backend/business/referral/getstore/`, data, this.httpOptions);
  }

  redeemAPi(id: any, data) {
    return this.http.post<any>(`${this.endpoint}/backend/store/referral/${id}/redeem/`, data, this.httpOptions);
  }
  numberVerify(data: any) {
    return this.http.post<any>(`${this.endpoint}/backend/store/check/new_phone/`, data, this.httpOptions);
  }
  optNumberVerify(data: any) {
    return this.http.post<any>(`${this.endpoint}/backend/store/check_phone/otp/verify/`, data, this.httpOptions);
  }
  referralVoucher(data) {
    return this.http.post<any>(`${this.endpoint}/backend/referral/voucher/list/`, data, this.httpOptions);

  }
  referralLink(data) {
    return this.http.post<any>(`${this.endpoint}/backend/business/referral/sort_url/`, data, this.httpOptions)
  }

  referralLinkData(data) {
    return this.http.post<any>(`${this.endpoint}/backend/referral/list/`, data, this.httpOptions)
  }
}
