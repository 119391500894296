import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef,OnDestroy } from "@angular/core";
import { Store } from "../models/store";
import { OrdersWithPayment } from "../models/order";
import { OrderService } from "../order.service";
import { CountDownService } from "../_services/count-down.service";
import { Subscription } from "rxjs";
import { IMqttMessage, IPublishOptions, MqttService } from "ngx-mqtt";
import { IClientSubscribeOptions } from "mqtt/types/lib/client-options";
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: "app-store-header",
  templateUrl: "./store-header.component.html",
  styleUrls: ["./store-header.component.sass"],
})
export class StoreHeaderComponent implements OnInit {
  openStore;
  closeStore;
  isStoreOpen = false;
  idStore: any;
  convertCurrentDate: any;
  fromdateTime: any;
  todateTIme: any;
  status: any;
  showDiscliamer: boolean;
  toggle: boolean;
  interval;
  isSocketActive: boolean;
  header: boolean = false;
  dateObject: Date;

  @Input() store: Store;
  @Input() shareURL: string;
  @Input() title: string;
  @Input() orderHash: string;
  @Input() userHash: string;

  @Input() showShare: boolean = false;
  @Input() showMenu: boolean = false;
  @Input() showHours: boolean = false;
  @Input() showLogo: boolean = false;
  @Input() showBack: boolean = false;
  @Input() showStoreInfo: boolean = false;
  @Input() showTitle: boolean = false;
  @Input() orderWithPayment: OrdersWithPayment;
  @Output() back = new EventEmitter<boolean>();
  logo;
  countdownFormatted: any;
  countdownValue: any;
  private countdownSubscription: Subscription;
  mqtSub;

  constructor(
    private orderService: OrderService,
    private countdownService: CountDownService,
    private changeDetectorRef: ChangeDetectorRef,
    private mqttService: MqttService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    console.log('STORE header');
    this.idStore = localStorage.getItem("currentStore");
    if (this.store) {
      this.orderService.getStoreLogo(this.store.id).subscribe((data) => {
        this.logo = data;
      });
    }

    if (this.userHash) {
      localStorage.setItem("userHash", this.userHash);
    }
    this.discliamerShownUpdate();

    // if (this.store.disclaimer_show == true) {
    //   if (!this.interval) {
    //     console.log(" after every 30 second");

    //     this.interval = setInterval(() => {
    //       this.discliamerShownUpdate();
    //     }, 30000);
    //   }
    // }

    this.mqttService.onConnect.subscribe(x => {
      this.isSocketActive = true;
      // this._snackBar.open('Connection MQTT established successfully.', 'OK', { duration: 2000 });
    });

    this.mqttService.onReconnect.subscribe(x => {
      // this._snackBar.open('Connection to the server is lost, retrying to establish a connection in 5 seconds.');
      // this.isListening = false;
    });

    this.mqttService.onClose.subscribe(x => {
      // this._snackBar.open('Connection to the server closed.');
      this.isSocketActive = false;
      // this.isListening = false;
    });

    setTimeout(() => {
      this.header == true;

      if (this.store) {
        this.orderService.getStoreLogo(this.store.id).subscribe((data) => {
          this.logo = data;
        });
      }

      if (this.userHash) {
        localStorage.setItem("userHash", this.userHash);
      }
      this.discliamerShownUpdate();
    }, 1500)


    /* COUNT DOWN BEGINS */
    const aa = localStorage.getItem('dayPause');
    const countLocalValue = localStorage.getItem('countdownValue');
    if (countLocalValue) {
      if ((this.store.pause_store == false && this.store.pause_store_whole_day == true) || (this.store.pause_store == true && this.store.pause_store_whole_day == false)) {
        if (aa == 'day_pause' || aa == 'whole_day_pause') {
          this.stopCountdown();
          setTimeout(() => {
            this.countDownTimer();
          }, 1500);
        }
      } else {
        this.countDownTimer();
      }
    } else {
      this.countDownTimer();
    }
    /* COUNT DOWN STOP */

    this.initMqtt();
  }

  ngOnDestroy(): void {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  goBack() {
    this.back.emit(true);
  }

  copyMessage(val: string) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this._snackBar.open("Link copied to clipboard!", "OK", {
      duration: 1000,
      verticalPosition: "top",
    });
  }

  discliamerShownUpdate() {
    if(this.store){
      if (this.store.disclaimer_show != undefined || this.store.disclaimer_show != null) {
        this.toggle = this.store.disclaimer_show;
      }
      this.fromdateTime = this.store.disclaimer_from_time;
      this.todateTIme = this.store.disclaimer_to_time;
      var current = new Date();
      this.convertCurrentDate = new Date(current).toISOString();

      console.log("toggle", this.toggle);
      console.log("from", this.fromdateTime);
      console.log("to", this.todateTIme);
      console.log("current", this.convertCurrentDate);

      if (this.toggle == true) {
        if (this.convertCurrentDate >= this.fromdateTime) {
          this.showDiscliamer = true;
        } else {
        }

        const keySend = {};
        this.orderService
          .updateDisclaimerShow(this.idStore, keySend)
          .subscribe((data: any) => {
            this.status = data.status;
          });
      }
    }
  }

  /* COUNT DOWN BEGINS */
  countDownTimer() {
    if(this.store){
      this.countdownValue = parseInt(localStorage.getItem('countdownValue'), 10);
      if (this.store.pause_store) {
        if (this.countdownValue > 0) {
          const countDownValue = (this.countdownValue / 60);
          this.startCountdown(countDownValue);
          this.subscribeToCountdownChanges();
          this.updateCountdownFormatted();
        } else {
          localStorage.setItem('dayPause', 'day_pause')
          const targetDate = new Date(this.store.store_pause_date_time);
          const currentDate = new Date();
          if (currentDate < targetDate) {
            const timeDifference = Math.abs(currentDate.getTime() - targetDate.getTime());
            const minutesDifference = (timeDifference / (1000 * 60));
            this.countdownService.setInitialCountdownValue(minutesDifference);
            this.subscribeToCountdownChanges();
            this.updateCountdownFormatted();
          }
        }
      } else if (this.store.pause_store_whole_day) {
        if (this.countdownValue > 0) {
          const countDownValue = (this.countdownValue / 60);
          this.startCountdown(countDownValue);
          this.subscribeToCountdownChanges();
          this.updateCountdownFormatted();
        } else {
          localStorage.setItem('dayPause', 'whole_day_pause');
          this.dateObject = new Date(this.store.store_whole_day_pause_date_time.replace('Z', ''));
          const currentDateInStoreTimeZone = new Date().toLocaleString("en-US", { timeZone: this.store.time_zone });
          const currentDate = new Date(currentDateInStoreTimeZone);
          if (currentDate < this.dateObject) {
            const timeDifference = Math.abs(currentDate.getTime() - this.dateObject.getTime());
            const minutesDifference = (timeDifference / (1000 * 60));
            console.log('Minutes DIfference', minutesDifference);
            this.countdownService.setInitialCountdownValue(minutesDifference);
            this.subscribeToCountdownChanges();
            this.updateCountdownFormatted();
          }
        }
      } else {
        localStorage.removeItem('countdownValue');
      }
    }
  }
  startCountdown(minutes: number): void {
    this.countdownService.startCountdown(minutes);
    this.countdownValue = this.countdownService.getCountdownValue();
    localStorage.setItem('countdownValue', this.countdownValue.toString());
    this.updateCountdownFormatted();
    this.changeDetectorRef.detectChanges();
  }

  stopCountdown(): void {
    this.countdownService.stopCountdown();
    this.countdownValue = this.countdownService.getCountdownValue();
    localStorage.removeItem('countdownValue');
    this.updateCountdownFormatted();
    this.changeDetectorRef.detectChanges();
  }

  private subscribeToCountdownChanges(): void {
    this.countdownSubscription = this.countdownService.subscribeToChanges().subscribe(() => {
      this.countdownValue = this.countdownService.getCountdownValue();
      this.updateCountdownFormatted();
      this.changeDetectorRef.detectChanges();
      if (this.countdownValue == 1) {
        localStorage.removeItem('countdownValue');
        const payload = {
          "pause_store": false,
          "pause_store_whole_day": false,
          "store_pause_time": null
        }
        this.orderService.pauseStoreService(this.store.id, payload).subscribe((data: any) => {
          location.reload();
        });
      }
    });
  }

  private updateCountdownFormatted(): void {
    this.countdownFormatted = this.countdownService.convertToHHMMSS(this.countdownValue);
  }
  /* COUNT DOWN STOP */

  /* MQTT IMPLEMENTATION */
  initMqtt() {
    let currentStoreId = localStorage.getItem("currentStore")
    this.mqtSub = this.mqttService.observe(`/order_app/${currentStoreId}/sub`,
      { qos: 1 } as IClientSubscribeOptions).subscribe((message: IMqttMessage) => {
        const response = JSON.parse(message.payload.toString());
        if (response) {
          location.reload();
        }
      });
  }

  publish(payload: string) {
    const qos = 2;
    if (this.store) {
      this.mqttService.unsafePublish(`/order_app/${this.store.id}/sub`, payload, { qos } as IPublishOptions);
    }
  }

  reconnectMqttServer() {
    this.mqttService.connect();
    const payload = {
      command: 'RECONNECT'
    };
    this.publish(JSON.stringify(payload));
  }

  removeLocal() {
    localStorage.removeItem('menu_group_name');
  }
}
