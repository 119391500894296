import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BotOrder} from '../../models/order';


export class OrderStatus{
  declined: boolean;
  submitting: boolean;
  preparing: boolean;
  ready: boolean;
  constructor(order: BotOrder) {
    this.declined = order.is_cancelled;
    this.submitting = !order.view_notified && !order.ready_notified;
    this.preparing = order.view_notified && !order.ready_notified;
    this.ready = order.view_notified && order.ready_notified;
  }
}
@Component({
  selector: 'app-order-progress',
  templateUrl: './order-progress.component.html',
  styleUrls: ['./order-progress.component.sass']
})
export class OrderProgressComponent implements OnChanges{

  @Input() order: BotOrder;
  status: OrderStatus;

  states = ['']

  ngOnChanges(changes: SimpleChanges) {
    if(changes.order){
      this.order = changes.order.currentValue;
      this.status = new OrderStatus(this.order);
      console.log(this.status)
    }
  }
}
