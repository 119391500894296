import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {filter} from 'rxjs/operators';
import {CardDetails} from '../../../models/order';
import {OrderService} from '../../../order.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-wallet-add-cardconnect',
  templateUrl: './wallet-add-cardconnect.component.html',
  styleUrls: ['./wallet-add-cardconnect.component.sass']
})
export class WalletAddCardconnectComponent implements OnInit{
  iTokenUrl: any;
  phoneNumber:string;
  storeId:string;
  paymentGateway: string;
  form:FormGroup;
  error: any
  @ViewChild("month",{static: true}) monthElement: ElementRef;
  @ViewChild("year",{static: true}) yearElement: ElementRef;
  @ViewChild("cvc",{static: true}) cvcElement: ElementRef;
  @ViewChild("nameOnCard",{static: true}) nameElement: ElementRef;

  constructor(private sanitizer:DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef : MatDialogRef<WalletAddCardconnectComponent>,
              private fb: FormBuilder,
              private orderService: OrderService,
              private router: Router,){
    console.log(data)
    this.iTokenUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${environment.iTokenUrl}/itoke/ajax-tokenizer.html?tokenizewheninactive=true&css=%0A%0Abody%7Bmargin%3A0px%3B%7D%20.error%7Bcolor%3A%20red%3B%7D%20input%7Bwidth%3A%2096%25%3B%20height%3A%2055px%3B%20border%3A%201px%20solid%20%23c1c1c1%3B%20border-radius%3A%205px%3B%20font-size%3A%2018px%3B%20padding%3A%200%205px%3B%7D%0A`);
    this.phoneNumber = this.data.phoneNumber;
    this.storeId = this.data.storeId;
    this.paymentGateway = this.data.paymentGateway;

    window.addEventListener('message', function (event) {
      var token = JSON.parse(event.data);
      localStorage.setItem('token', token.message)
    });
  }

  ngOnInit() {
    localStorage.setItem('token', null)
    const monthMaxLength = 2;
    const yearMaxLength = 2;
    const cvcMaxLength = 4;
    this.form = this.fb.group({
      month: ["", [Validators.required, Validators.maxLength(monthMaxLength)]],
      year: ["", [Validators.required, Validators.maxLength(yearMaxLength)]],
      cvc: ["", [Validators.required, Validators.maxLength(cvcMaxLength)]],
      nameOnCard: ["", [Validators.required]],
      addtoWallet: [true]
    })

    this.form.get("month").valueChanges.pipe(filter((value: string) => value.length === monthMaxLength))
      .subscribe(() => this.yearElement.nativeElement.focus());

    this.form.get("year").valueChanges.pipe(filter((value: string) => value.length === yearMaxLength))
      .subscribe(() => this.cvcElement.nativeElement.focus());

    this.form.get("cvc").valueChanges.pipe(filter((value: string) => value.length === cvcMaxLength))
      .subscribe(() => this.nameElement.nativeElement.focus());
  }

  submit(){
    if(this.form.valid){
      this.orderService.addNewCard(this.storeId,
        this.phoneNumber,
        this.form.get("nameOnCard").value,
        this.form.get("month").value,
        this.form.get("year").value,
        this.form.get("cvc").value,
        localStorage.getItem('token'),
        this.form.get('addtoWallet').value).subscribe(
        response => {
          let cardDetail = Object.assign(new CardDetails(), response);
          this.dialogRef.close(cardDetail);
        },
        error => {
          this.error = error;
        })
    }
  }

  dismiss(){
    this.dialogRef.close()
  }

  hasToken(){
    const token = localStorage.getItem('token');
    return token && token.length === 16 && this.form.valid;
  }
}
