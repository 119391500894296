import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { Store } from 'src/app/models/store';
import { OrderService } from 'src/app/order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-referral-promotion',
  templateUrl: './referral-promotion.component.html',
  styleUrls: ['./referral-promotion.component.sass']
})
export class ReferralPromotionComponent {
  store;
  offerCatalogSharableLink;
  storeId: any;
  isLoading = true;
  store_id: any;
  hash: any;
  phoneNumber: any;
  FilterPromot: string;
  hideHeader: boolean = false;
  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    ngNavigatorShareService: NgNavigatorShareService,
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
  }
  private ngNavigatorShareService: NgNavigatorShareService;

  ngOnInit(): void {
    if (environment.referralHost.includes(window.location.origin)) {
      this.hideHeader = true;
    }
    this.storeId = this.route.snapshot.paramMap.get('storeId');
    this.hash = this.route.snapshot.paramMap.get('userHash');
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.store_id = localStorage.getItem('currentStore');
    this.FilterPromot = JSON.parse(localStorage.getItem("referee_gets"));

    this.orderService.getStoreInfo(this.storeId).subscribe(store => {
      this.store = Object.assign(new Store(), store);
      if (this.store.dynamic_offer_url) {
        this.offerCatalogSharableLink = this.store.dynamic_offer_url;
      } else {
        this.offerCatalogSharableLink = `${environment.hostName}/store/${this.store.url_slug}/offers/`
      }
      this.isLoading = false;
    });
  }

  goBack() {
    window.history.back()
  }

  nextPage() {
    this.router.navigate(['store/' + this.storeId + '/referral-promotion-value']);
  }
}
